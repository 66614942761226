import React, { useEffect, useState } from "react";
import { Table, Tag, Space, Button, Drawer, Input, Modal, Spin } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import axios from "axios";
import Select from "react-select";
import Header from "../../layout/Dashboard/Header";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Group from "./Group";
import { Tooltip as ReactTooltip } from "react-tooltip";
const { confirm } = Modal;
// import Drawer  from '../../layout/Dashboard/Drawer'

const EmployeeTable = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isDrawerVisible1, setIsDrawerVisible1] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [passwordOption, setPasswordOption] = useState("auto");
  const [data, setData] = useState([]);
  const [groupname, setGroupname] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dpdesc, setDpdesc] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([
    {
      key: "1",
      employeeId: "#3066",
      dateOfAssigning: "Jan 6, 2022",
      recruiterEmail: "olivia@untitledui.com",
      assignedGroup: "Group A",
      status: "Active",
      password: "sdgvrf12",
    },
    // ... other data
  ]);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleCreateGroup = () => {
    // Add logic to handle the creation of the group here
    console.log("Group created");
    closeModal();
  };
  const navigate = useNavigate();
  const columns = [
    // {
    //   title: "Employee ID",
    //   dataIndex: "user_id",
    //   key: "user_id",
    // },
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date of Addition",
      dataIndex: "createddt",
      key: "createddt",
      render: (is_active, record) => (
       
          new Date(is_active).toISOString().split('T')[0]
       
      ),
    },
    {
      title: "Today's Searches",
      dataIndex: "today_search",
      key: "today_search",
    },
    {
      title: "Overall Searches",
      dataIndex: "overall_search",
      key: "overall_search",
    },
    {
      title: "Recruiter Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Assigned Group",
      dataIndex: "group_name",
      key: "group_name",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active, record) => (
        <Tag
          color={is_active === true ? "green" : "red"}
          onClick={() => showDeleteConfirm(record)}
        >
          {is_active === true ? `Active` : `In-active`}
        </Tag>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            id="edit"
            icon={<EditOutlined />}
            onClick={() => showDrawer(record)}
          />
          <Button
            id="reset"
            icon={<KeyOutlined style={{ fontSize: "20px", color: "black" }} />}
            onClick={() => showDrawer1(record)}
          />
          <ReactTooltip
            anchorId="reset" // ID of the element to attach the tooltip
            place="bottom"
            content="Reset Password"
            style={{ backgroundColor: "#333", color: "#fff" }} // Custom style if needed
          />
          <ReactTooltip
            anchorId="edit" // ID of the element to attach the tooltip
            place="bottom"
            content="Edit Profile"
            style={{ backgroundColor: "#333", color: "#fff" }} // Custom style if needed
          />
        </Space>
      ),
    },
  ];

  const generatePassword = (length = 12) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const showDrawer = (record) => {
    console.log(record);
    setEditingRecord(record);
    setIsDrawerVisible(true);
  };

  const showDrawer1 = (record) => {
    console.log(record);
    setEditingRecord(record);
    setIsDrawerVisible1(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };
  const handleDrawerClose1 = () => {
    setIsDrawerVisible1(false);
  };

  const handleEditOk = (values) => {
    // Update the data source with the new values
    const updatedDataSource = dataSource.map((item) => {
      if (item.key === editingRecord.key) {
        return { ...item, ...values };
      }
      return item;
    });

    setDataSource(updatedDataSource);
    setIsDrawerVisible(false);
  };
  const HandleRecruiter = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/onboard/user`);
      setData(response.data[`data`]);
      console.log(response);
    } catch (err) {
      // setError(err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    HandleRecruiter();
  }, []);

  const token = sessionStorage.getItem("access_token");
  const validationSchema = Yup.object({
    name: Yup.string().required("Employee name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    // jobTitle: Yup.string().required("Job Title is required"),
    // username: Yup.string().email("Invalid email address").required("Username is required"),
    // password: Yup.string().when('passwordOption', {
    //   is: (value) => value === 'custom',
    //   then: (schema) => schema.required('Password is required'),
    //   otherwise: (schema) => schema,
    // }),
    // confirmPassword:Yup.string().when('passwordOption', {
    //   is: (value) => value === 'custom',
    //   then: (schema) => schema.oneOf([Yup.ref("password"), null], "Passwords must match").required("Confirm Password is required"),
    //   otherwise: (schema) => schema,
    // //  Yup.string().when("passwordOption", {
    // //   is:(value) => value === "custom" ,
    // //   then: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required("Confirm Password is required"),
    // }),
    group: Yup.string().required("Group selection is required"),
    // user_type: Yup.string().required("User Type selection is required"),
  });

  const validationSchema1 = Yup.object({
    // name: Yup.string().required("Employee name is required"),
    // email: Yup.string().email("Invalid email address").required("Email is required"),
    // phoneNumber: Yup.string().required("Phone Number is required"),
    // jobTitle: Yup.string().required("Job Title is required"),
    // username: Yup.string().email("Invalid email address").required("Username is required"),
    password: Yup.string().when("passwordOption", {
      is: (value) => value === "custom",
      then: (schema) => schema.required("Password is required"),
      otherwise: (schema) => schema,
    }),
    confirmPassword: Yup.string().when("passwordOption", {
      is: (value) => value === "custom",
      then: (schema) =>
        schema
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required"),
      otherwise: (schema) => schema,
      //  Yup.string().when("passwordOption", {
      //   is:(value) => value === "custom" ,
      //   then: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required("Confirm Password is required"),
    }),
    old_password: Yup.string().required("Old password is required"),
  });
  const showDeleteConfirm = (record) => {
    console.log(record);
    confirm({
      title: `Are you sure you want to ${
        record.is_active === true ? `InActive` : ` Active`
      } this record?`,
      icon: <ExclamationCircleOutlined />,
      content: `Employee Name: ${record.name}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        // Handle delete logic here
        axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/user/active`, {
            user_id: record.user_id,
            active: record.is_active === true ? 0 : 1,
          })
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                // title: 'Error!',
                text: `${response.data.message}`,
                // text:`Onboarded Successfully`,
                icon: "info",
                confirmButtonText: "Ok",
              });
            }
            if (response.status === 201) {
              handleDrawerClose();
              Swal.fire({
                // title: 'Error!',
                text: `${response.data[`Message`]}`,
                // text:`Onboarded Successfully`,
                icon: "success",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              // title: 'Error!',
              text: `${err.response.data[`User Message`]}`,
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
        console.log("Deleted:", record);
      },
      onCancel() {
        console.log("Delete canceled");
      },
    });
  };

  const initialValues = {
    name: editingRecord?.name,
    email: editingRecord?.email,
    phoneNumber: editingRecord?.mobile,
    // jobTitle: "",
    // username: "",
    user_id: editingRecord?.user_id,
    password: generatePassword(),
    confirmPassword: "",
    group: editingRecord?.group_id,
     user_type:editingRecord?.user_type
  };

  console.log(initialValues)
  const initialValues1 = {
    // name: editingRecord?.name,
    // email:editingRecord?.email,
    // phoneNumber:editingRecord?.mobile,
    // jobTitle: "",
    // username: "",
    user_id: editingRecord?.user_id,
    password: generatePassword(),
    confirmPassword: "",
    // group: editingRecord?.group_id,
    old_password: "",
  };

  const HandleOnboard = async (values) => {

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/user/onboard/user`,
        {
          name: values.name,
          username: values.email,
          mobile: values.phoneNumber,
          email: values.email,
          // "password": values.password,
          user_id: values.user_id,
          group_id: values.group,
          user_type:values.user_type
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Optional, set to match the API's requirements
          },
        }
      );
      // setData(response.data);
      console.log(response);
      // sessionStorage.setItem(`reg_id`, response.data[`Registration ID`])
      // sessionStorage.setItem(`email`, response.data.email)
      // sessionStorage.setItem(`access_token`, response.data.token.access)
      // sessionStorage.setItem(`refresh_token`, response.data.token.refresh)
      // Navigate(`/dashboard`)
      if (response.status === 200) {
        Swal.fire({
          // title: 'Error!',
          text: `${response.data.message}`,
          // text:`Onboarded Successfully`,
          icon: "info",
          confirmButtonText: "Ok",
        });
        HandleRecruiter()
      }
      if (response.status === 201) {
        handleDrawerClose();
        Swal.fire({
          // title: 'Error!',
          text: `${response.data[`Message`]}`,
          // text:`Onboarded Successfully`,
          icon: "success",
          confirmButtonText: "Ok",
        });
        HandleRecruiter()
      }
    } catch (err) {
      // setError(err.message);
      console.log(err);
      Swal.fire({
        // title: 'Error!',
        text: `${err.response.data[`User Message`]}`,
        icon: "error",
        confirmButtonText: "Ok",
      });
      HandleRecruiter()
    } finally {
      // setLoading(false);
    }
  };

  const HandleOnboard1 = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/reset/password`,
        {
          // "name": values.name,
          // "username": values.email,
          // "mobile": values.phoneNumber,
          // "email": values.email,
          new_password: values.password,
          user_id: values.user_id,
          old_password: values.old_password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Optional, set to match the API's requirements
          },
        }
      );
      // setData(response.data);
      console.log(response);
      // sessionStorage.setItem(`reg_id`, response.data[`Registration ID`])
      // sessionStorage.setItem(`email`, response.data.email)
      // sessionStorage.setItem(`access_token`, response.data.token.access)
      // sessionStorage.setItem(`refresh_token`, response.data.token.refresh)
      // Navigate(`/dashboard`)
      setIsDrawerVisible1(true);
      if (response.status === 200) {
        Swal.fire({
          // title: 'Error!',
          text: `${response.data.message}`,
          // text:`Onboarded Successfully`,
          icon: "success",
          confirmButtonText: "Ok",
        });
        HandleRecruiter()
      }
      if (response.status === 201) {
        handleDrawerClose();
        Swal.fire({
          // title: 'Error!',
          text: `${response.data[`Message`]}`,
          // text:`Onboarded Successfully`,
          icon: "success",
          confirmButtonText: "Ok",
        });
        HandleRecruiter()
      }
    } catch (err) {
      // setError(err.message);
      console.log(err);
      Swal.fire({
        // title: 'Error!',
        text: `${err.response.data[`User Message`]}`,
        icon: "error",
        confirmButtonText: "Ok",
      });
      HandleRecruiter()
    } finally {
      // setLoading(false);
    }
  };

  const handleSubmit = (values) => {
    console.log("Form values:", values);
    // Handle form submission logic here
    HandleOnboard(values);
  };
  const handleSubmit1 = (values) => {
    console.log("Form values:", values);
    // Handle form submission logic here
    HandleOnboard1(values);
  };
  const handleFetchGroup = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/access/group`)
      .then((res) => {
        console.log(res.data);
        //  setDirectory(res.data.data.map((ele)=>({label:ele.key, value:ele.value})))
        setGroupname(
          res.data.data.map((ele) => ({
            label: ele.group_name,
            value: ele.group_id,
            location: ele?.location,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleFetchGroup();
  }, []);
  console.log(editingRecord);

  
  const userG=[
    {
      label:'Admin',
      value:'admin'
    },
    {
      label:'Recruiter',
      value:'recruiter'
    }
  ]
  return (
    <>
      {/* <Header /> */}
      <Box sx={{ display: "flex", width: "100%" }}>
        {/* <Drawer/> */}
        <div style={{ width: `100%` }}>
          {isModalOpen && (
            <Group
              handleCreateGroup={handleCreateGroup}
              closeModal={closeModal}
            />
          )}
          <button
            className="back-button1"
            onClick={() => navigate(`/dashboard`)}
          >
            ← Back
          </button>
          <Table
            columns={columns}
            dataSource={data}
            pagination={true}
            loading={{ spinning: loading, indicator: <Spin size="large" /> }}
          />

          {/* Edit Drawer */}
          <Drawer
            title="Edit Employee"
            width={800}
            zIndex={1000000000}
            onClose={handleDrawerClose}
            visible={isDrawerVisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form className="recruiter-form">
                  {/* Add Recruiter Section */}
                  <div className="section">
                    <h3>
                      <b>Add Recruiter</b>
                    </h3>
                    <p className="head">
                      Please fill the user details to add as recruiter
                    </p>
                    <div className="form-row">
                      <div className="form-group">
                        <label>Employee Name*</label>
                        <Field
                          name="name"
                          type="text"
                          placeholder="Employee Name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group">
                        <label>Email Address*</label>
                        <Field
                          name="email"
                          type="email"
                          placeholder="Email Address"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group">
                        <label>Phone Number*</label>
                        <Field
                          name="phoneNumber"
                          type="text"
                          placeholder="Phone Number"
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group"></div>
                    </div>
                  </div>

                  {/* Access Credentials Section
            <div className="section">
              <h3 className="head"><b>Access Credentials</b></h3>
              <div className="form-row">
                <div className="form-group">
                  <label>Username*</label>
                  <Field name="email" type="email" placeholder="Email@gmail.com" disabled />
             
                </div>
                <div className="form-group password-options">
                  <label>Password Option*</label>
                  <div className="radio-group" >
                    <label>
                      <input
                        type="radio"
                        value="auto"
                        checked={passwordOption === "auto"}
                        onChange={() => {
                          setPasswordOption("auto");
                          // const autoGeneratedPassword = generatePassword();
                          setFieldValue("password", generatePassword());
                          setFieldValue("confirmPassword",generatePassword());
                          setFieldValue("passwordOption", "auto");
                        }}
                      />
                      Auto Generate*
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="custom"
                        checked={passwordOption === "custom"}
                        onChange={() => {
                          setPasswordOption("custom");
                          setFieldValue("passwordOption", "custom");
                        }}
                      />
                      Custom*
                    </label>
                  </div>
                </div>
              </div>
              {passwordOption === "custom" && (
                <div className="form-row">
                  <div className="form-group">
                    <label>Create Password*</label>
                    <Field name="password" type="password" placeholder="************" />
                    <ErrorMessage name="password" component="div" className="error" />
                  </div>
                  <div className="form-group">
                    <label>Confirm Password*</label>
                    <Field name="confirmPassword" type="password" placeholder="************" />
                    <ErrorMessage name="confirmPassword" component="div" className="error" />
                  </div>
                </div>
              )}
            </div> */}

                  {/* Access Control Section */}
                  <div className="section">
                    <h3>
                      <b>Access Control</b>
                    </h3>
                    <p className="head">
                      Please select the group from below dropdown to give access
                      to recruiter or else customize by creating a new group
                    </p>
                    <div className="form-row">
                      <div className="form-group">
                        <label>Select Group*</label>
                        <Select
                          options={groupname}
                          name="group"
                          value={groupname.find(
                            (option) => option.value === values.group
                          )}
                          onChange={(option) => {
                            setFieldValue("group", option.value);
                            // setDpdesc(
                            //   groupname.find(
                            //     (option) => option.value === values.group
                            //   )
                            // );
                          }}
                        />
                        <ErrorMessage
                          name="group"
                          component="div"
                          className="error"
                        />
                      </div>
                      {/* <div className="form-group">
                    <label>Select User Type*</label>
                    <Select
                      options={userG}
                      name="user_type"
                      value={userG.find(
                        (option) => option.value === values.user_type
                      )}
                      onChange={(option) => {
                        setFieldValue("user_type", option.value);
                        
                      }}
                    />
                    <ErrorMessage
                      name="user_type"
                      component="div"
                      className="error"
                    />
                  </div> */}
                      <div className="form-group">
                        <div className="group-description">
                          <strong>Selected Group Description</strong>
                          <p>
                            Directories:{groupname.find(
                            (option) => option.value === values.group
                          )?.location?.join(',')}
                           
                          </p>
                          {/* <p>Country: UAE, Saudi Arabia</p>
                    <p>Category: Human Resource, Retail, IT</p>
                    <p>Role: Leasing executive, Market Research Executive</p> */}
                        </div>
                      </div>
                      {/* <button type="button" className="create-group-button" onClick={openModal}>
                  Create New Group
                </button> */}
                    </div>
                  </div>

                  {/* Form Actions */}
                  <div className="actions">
                    <button type="button" className="cancel-button">
                      Cancel
                    </button>
                    <button type="submit" className="save-button">
                      Save & Proceed
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Drawer>
          <Drawer
            title="Reset Password"
            width={800}
            zIndex={1000000000}
            onClose={handleDrawerClose1}
            visible={isDrawerVisible1}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Formik
              initialValues={initialValues1}
              enableReinitialize={true}
              validationSchema={validationSchema1}
              onSubmit={handleSubmit1}
            >
              {({ setFieldValue, values }) => (
                <Form className="recruiter-form">
                  {/* Add Recruiter Section */}

                  <div className="section">
                    <h3 className="head">
                      <b>Reset Password</b>
                    </h3>
                    <div className="form-row">
                      <div className="form-group">
                        <label>Username*</label>
                        <Field
                          name="email"
                          type="email"
                          placeholder="Email@gmail.com"
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label>Old Password</label>
                        <Field name="old_password" type="password" />
                        <ErrorMessage
                          name="old_password"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group password-options">
                        <label>New Password Option*</label>
                        <div className="radio-group">
                          <label>
                            <input
                              type="radio"
                              value="auto"
                              checked={passwordOption === "auto"}
                              onChange={() => {
                                setPasswordOption("auto");
                                // const autoGeneratedPassword = generatePassword();
                                setFieldValue("password", generatePassword());
                                setFieldValue(
                                  "confirmPassword",
                                  generatePassword()
                                );
                                setFieldValue("passwordOption", "auto");
                              }}
                            />
                            Auto Generate*
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="custom"
                              checked={passwordOption === "custom"}
                              onChange={() => {
                                setPasswordOption("custom");
                                setFieldValue("passwordOption", "custom");
                              }}
                            />
                            Custom*
                          </label>
                        </div>
                      </div>
                    </div>
                    {passwordOption === "custom" && (
                      <div className="form-row">
                        <div className="form-group">
                          <label>Create Password*</label>
                          <Field
                            name="password"
                            type="password"
                            placeholder="************"
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-group">
                          <label>Confirm Password*</label>
                          <Field
                            name="confirmPassword"
                            type="password"
                            placeholder="************"
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Form Actions */}
                  <div className="actions">
                    <button type="button" className="cancel-button">
                      Cancel
                    </button>
                    <button type="submit" className="save-button">
                      Save & Proceed
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Drawer>
        </div>
      </Box>
    </>
  );
};

export default EmployeeTable;
