// import React, { useState } from "react";
import Modal from "react-modal";
import './Style.css' 
const GroupCreatedModal = ({ isOpen, onRequestClose }) => {
  console.log(isOpen)
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Group Created Modal"
      className="Modal"
      overlayClassName="Overlay"
    >
      <div className="modal-content">
        <div className="modal-header">
          <img
            src="https://img.icons8.com/color/96/checkmark.png"
            alt="success"
            className="success-icon"
          />
          <h2>Group Created Successfully</h2>
          <p>You can select this group while giving access</p>
        </div>
        <div className="modal-body">
          <div className="group-details">
            <div className="detail">
              <strong>Group Name:</strong> Group Z
            </div>
            <div className="detail">
              <strong>Country:</strong> Dubai, Rabat
            </div>
            <div className="detail">
              <strong>Category:</strong> Human Resource, Retail, Information
              Technology
            </div>
            <div className="detail">
              <strong>Role:</strong> Market Research Executive
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GroupCreatedModal;
