// assets
import { DashboardOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    // {
    //   id: 'dashboard',
    //   title: 'Dashboard',
    //   type: 'item',
    //   url: '/dashboard/default',
    //   icon: icons.DashboardOutlined,
    //   breadcrumbs: false
    // },
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
    // {
    //   id: 'recruiters',
    //   title: 'Recruiters List',
    //   type: 'item',
    //   url: '/recruiters',
    //   icon: icons.DashboardOutlined,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'recruiter-register',
    //   title: 'Recruiters Registration',
    //   type: 'item',
    //   url: '/recruiter-register',
    //   icon: icons.DashboardOutlined,
    //   breadcrumbs: false
    // }
  ]
};

export default dashboard;
