import React, { useEffect } from "react";
import { RouterProvider } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from "./routes/AuthContext";
// project import
import router from './routes';
import ThemeCustomization from './themes';
import Login from './pages/authentication/login';
import ScrollTop from './components/ScrollTop';
import Dashboard from './pages/Card/Card';
import EmployeeTable from './pages/Recruiter/Recruiter';
import AddRecruiterForm from './pages/authentication/register';
import Header from './layout/Dashboard/Header';
import Drawer from './layout/Dashboard/Drawer'
// ==============================|| APP - THEME, ROUTER, LOCAL ||============================== //

export default function App() {
  useEffect(() => {
    // Function to disable right-click
    const disableRightClick = (event) => {
      event.preventDefault();
    };

    // Add event listener to the document
    document.addEventListener("contextmenu", disableRightClick);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);
  const path= window.location.pathname
  console.log(path)
  return (
    <AuthProvider>
    <ThemeCustomization>
      <ScrollTop>
    
    <RouterProvider router={router} />

        {/* {
         path !=='/'&& 
       
         <Header/>
         
        
        
        }
         <Drawer/>
      
        
        {/* <Router> */}
      {/* <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/recruiters" element={<EmployeeTable />} />
        <Route path="/recruiter-register" element={<AddRecruiterForm/>} />

      </Routes> */}
    {/* </Router> */}
      </ScrollTop>
    </ThemeCustomization>
    </AuthProvider>
  );
}
