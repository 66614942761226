import React, { useState, useEffect } from "react";
import "./login.css"; // Import your CSS file here
import logo from "../../assets/images/users/hire_rightt_hr_consultancy_logo 1 (1).png";
// import backImg from  '../../assets/images/users/dddepth-282 1.png'
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router";
import { useAuth } from "../../routes/AuthContext";
function Login() {
  const [showPassword, setShowPassword] = useState(false);
  // const backendUrl = import.meta.env.VITE_BACKEND_URL;
  const { login } = useAuth();
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const Navigate = useNavigate();
  const HandleLogin = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/login/user`,
        // `/user/login/user`,
        values
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // }
      );
      const { data } = response;

      // Store tokens and user details in sessionStorage
      sessionStorage.setItem(`reg_id`, data[`Registration ID`]);
      sessionStorage.setItem(`email`, data.email);
      sessionStorage.setItem(`name`, data.Name);
      sessionStorage.setItem(`die_access`, data.access_location);
      sessionStorage.setItem(`access_token`, data.token.access);
      sessionStorage.setItem(`refresh_token`, data.token.refresh);
      sessionStorage.setItem(`user_type`, data.user_type);

      // Update authentication context
      login({
        reg_id: data[`Registration ID`],
        email: data.email,
        name: data.Name,
        die_access: data.access_location,
        access_token: data.token.access,
        refresh_token: data.token.refresh,
      });

      Navigate(`/dashboard`);
    } catch (err) {
      console.error(err.response);
      Swal.fire({
        text: `${err.response.data[`User Message`]}`,
        icon: "error",
        confirmButtonText: "Ok",
      });
    } finally {
      // Optionally handle loading state here
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Please Enter Valid Email Id"),
      password: Yup.string().required("Please enter correct password"),
    }),
    onSubmit: (values) => {
      // Handle form submission
      console.log("Form data", values);
      HandleLogin(values);
    },
  });

  return (
    <div className="container2">
      <div className="left-section">
        <div className="text-content">
          <img src={logo} alt="Hire Rightt Logo" className="logo" />{" "}
          {/* Replace with your logo */}
          <h1>
            HIRING TOP EXECUTIVES FOR A BETTER TOMORROW, ONE LEADER AT A TIME
          </h1>
          <p>ENABLING ORGANISATIONS, EMPOWERING INDIVIDUALS</p>
        </div>
      </div>
      <div className="right-section">
        <div className="login-box">
          <img src={logo} alt="Hire Rightt Logo" className="logo" />{" "}
          {/* Replace with your logo */}
          <h2>Hey, Hello 👋</h2>
          <p>Enter your login details</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="input-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Please enter valid email "
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <button
                type="button"
                className="show-password"
                onClick={() => toggleShowPassword()}
              >
                {showPassword ? "👁️" : "🙈"}
              </button>
              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>
            <button type="submit" className="login-button">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
