import React from "react";
import Modal from "react-modal";

// Ensure the modal is properly styled
Modal.setAppElement("#root");

const ProgressModal = ({ modalIsOpen, progress, closeModal }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Progress Modal"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "500px",
          height:`200px`,
          textAlign: "center",
          zIndex:`1000000000000`,
          fontSize:`16px`
        },
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimmed background
          },
      }}
    >
      <h2>{progress<100?`Processing...`:`Uploaded`}</h2>
      <progress value={progress} max="100"></progress>
      <p>{progress}%</p>
      <button className="close-btn" onClick={closeModal}>Close</button>
    </Modal>
  );
};

export default ProgressModal;
