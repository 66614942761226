import PropTypes from "prop-types";
import { useMemo } from "react";

// Material-UI imports
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Project imports
import Palette from "./palette";
import Typography from "./typography";
import CustomShadows from "./shadows";
import componentsOverride from "./overrides";

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({ children }) {
  // Create theme
  const theme = useMemo(() => {
    const palette = Palette("light", "default");
    const typography = Typography(`'Public Sans', sans-serif`);
    const customShadows = CustomShadows(palette);

    return createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1440,
        },
      },
      direction: "ltr",
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: palette.palette,
      customShadows,
      typography,
      components: componentsOverride(
        createTheme({
          palette: palette.palette,
          typography,
          customShadows,
        })
      ),
    });
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeCustomization.propTypes = {
  children: PropTypes.node,
};
