import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ children }) => {

  const { contextValue} = useAuth();
  console.log(contextValue)

  if (sessionStorage.getItem(`access_token`)===null) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;