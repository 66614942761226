import React from "react";
import { FaBuilding, FaMapMarkerAlt, FaUsers, FaKey, FaFileAlt, FaCheck, FaClipboard, FaGift } from "react-icons/fa";

function Stepper() {
  const steps = [
    { label: "Company", icon: <FaBuilding /> },
    { label: "Locations", icon: <FaMapMarkerAlt /> },
    { label: "Users", icon: <FaUsers /> },
    { label: "User Access", icon: <FaKey /> },
    { label: "Documents", icon: <FaFileAlt /> },
    { label: "Verification", icon: <FaCheck /> },
    { label: "Consent", icon: <FaClipboard /> },
    { label: "Final Review", icon: <FaGift /> },
  ];

  return (
    <div style={styles.stepper}>
      {steps.map((step, index) => (
        <div key={index} style={styles.step}>
          <div style={styles.stepContent}>
            <div style={styles.icon}>{step.icon}</div>
            <span style={styles.label}>{step.label}</span>
          </div>
          {index < steps.length - 1 && <div style={styles.divider}></div>}
        </div>
      ))}
    </div>
  );
}

const styles = {
  stepper: {
    display: "flex",
    alignItems: "center",
    background: "#f0f0f0",
    padding: "10px",
    borderRadius: "8px",
  },
  step: {
    display: "flex",
    alignItems: "center",
  },
  stepContent: {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    background: "#2c1a6c",
    color: "#fff",
    borderRadius: "20px",
  },
  icon: {
    marginRight: "8px",
  },
  label: {
    fontWeight: "bold",
  },
  divider: {
    width: "20px",
    height: "2px",
    background: "#2c1a6c",
    margin: "0 10px",
  },
};

export default Stepper;
