import React, { useRef, useState } from "react";
import AWS from "aws-sdk";
import Swal from "sweetalert2";
import ProgressModal from "./ProgressModal";
import uploadf from "../../assets/images/users/Export.png";
const UploadZipToS3 = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
  };
  // Configure AWS SDK with environment variables
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const fileInputRef = useRef();

  // const handleFileInput = (event) => {
  //   setSelectedFile(event.target.files[0]);
  // };

  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;

  // Handle file input change
  const handleFileInput = (e) => {
    uploadFile(e);
  };
  const handleClick = () => {
    fileInputRef.current.click(); // Programmatically trigger the click event of the hidden input
  };

  // Upload file to S3
  const uploadFile = (e) => {
    const file = e.target.files[0];
    console.log(file);
    // Check if file is a ZIP file
    if (
      file &&
      (file.type === "application/zip" ||
        file.type === "application/x-zip-compressed")
    ) {
      // setSelectedFile(file);
      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
      });
      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
        Body: file,
        ACL: "private", // Adjust according to your needs
        ContentType: "application/zip",
      };

      // Start uploading the file
      s3.upload(params)
        .on("httpUploadProgress", (evt) => {
          openModal();
          setProgress(Math.round((evt.loaded / evt.total) * 100));

          // showProgressBar(Math.round((evt.loaded / evt.total) * 100))
        })
        .send((err, data) => {
          if (err) {
            console.error("Error uploading file: ", err);
          } else {
            console.log("File uploaded successfully: ", data.Location);
          }
        });
    } else {
      alert("Please upload a ZIP file.");
    }
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }} // Hide the file input
        onChange={handleFileInput}
        accept=".zip"
      />
      {/* <button >Choose File</button> Visible button */}
      {/* <button style={{
        border: '1px solid #dfdfdf',
        padding: '10px 10px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '7px',
      }} onClick={handleClick}></button> */}
      <button className="hover-button" onClick={handleClick}>
        <span className="icon">
          <img src={uploadf} />
        </span>
        <span className="text">Upload Zip </span>
      </button>
      {/* <d>Upload Progress: {progress}%</d
    iv> */}
      <ProgressModal
        modalIsOpen={modalIsOpen}
        progress={progress}
        closeModal={closeModal}
      />
    </div>
  );
};

export default UploadZipToS3;
