/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import "./register.css"; // Assuming you save the CSS as AddRecruiterForm.css
import Group from "../../pages/Recruiter/Group";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import Drawer from "../../layout/Dashboard/Drawer";
import { useEffect } from "react";
// Validation schema using Yup
const validationSchema = Yup.object({
  name: Yup.string().required("Employee name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  // jobTitle: Yup.string().required("Job Title is required"),
  // username: Yup.string().email("Invalid email address").required("Username is required"),
  password: Yup.string().when("passwordOption", {
    is: (value) => value === "custom",
    then: (schema) => schema.required("Password is required"),
    otherwise: (schema) => schema,
  }),
  confirmPassword: Yup.string().when("passwordOption", {
    is: (value) => value === "custom",
    then: (schema) =>
      schema
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    otherwise: (schema) => schema,
    //  Yup.string().when("passwordOption", {
    //   is:(value) => value === "custom" ,
    //   then: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required("Confirm Password is required"),
  }),
  group: Yup.string().required("Group selection is required"),
  user_type: Yup.string().required("User Type selection is required"),
});

const AddRecruiterForm = () => {
  const [passwordOption, setPasswordOption] = useState("auto");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupname, setGroupname] = useState([]);
  const [dpdesc, setDpdesc] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const navigate = useNavigate();
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const toggleShowPassword1 = () => {
    setShowPassword1((prevState) => !prevState);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCreateGroup = () => {
    // Add logic to handle the creation of the group here
    console.log("Group created");
    closeModal();
  };
  const generatePassword = (length = 12) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const userG=[
    {
      label:'Admin',
      value:'admin'
    },
    {
      label:'Recruiter',
      value:'recruiter'
    }
  ]

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    // jobTitle: "",
    // username: "",
    password: generatePassword(),
    confirmPassword: "",
    group: "",
    user_type:''
  };

  const HandleOnboard = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/onboard/user`,
        {
          name: values.name,
          Username: values.email,
          mobile: values.phoneNumber,
          email: values.email,
          password: values.password,
          group_id: values.group,
          user_type:values.user_type
        }
      );
      // setData(response.data);
      console.log(response);
      // sessionStorage.setItem(`reg_id`, response.data[`Registration ID`])
      // sessionStorage.setItem(`email`, response.data.email)
      // sessionStorage.setItem(`access_token`, response.data.token.access)
      // sessionStorage.setItem(`refresh_token`, response.data.token.refresh)
      // Navigate(`/dashboard`)
      if (response.status === 200) {
        Swal.fire({
          // title: 'Error!',
          text: `${response.data.message}`,
          // text:`Onboarded Successfully`,
          icon: "info",
          confirmButtonText: "Ok",
        });
        handleFetchGroup();
      }
      if (response.status === 201) {
        Swal.fire({
          // title: 'Error!',
          text: `${response.data[`User Message`]}`,
          // text:`Onboarded Successfully`,
          icon: "success",
          confirmButtonText: "Ok",
        });
        handleFetchGroup();
      }
    } catch (err) {
      // setError(err.message);
      console.log(err);
      Swal.fire({
        // title: 'Error!',
        text: `${err.response.data[`User Message`]}`,
        icon: "error",
        confirmButtonText: "Ok",
      });
      handleFetchGroup();
    } finally {
      // setLoading(false);
    }
  };

  const handleSubmit = (values) => {
    console.log("Form values:", values);
    // Handle form submission logic here
    HandleOnboard(values);
  };

  const groupOptions = [
    { value: 1, label: "Group A" },
    { value: 2, label: "Group B" },
    // Add other groups here
  ];

  const handleFetchGroup = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/access/group`)
      .then((res) => {
        console.log(res.data);
        //  setDirectory(res.data.data.map((ele)=>({label:ele.key, value:ele.value})))
        setGroupname(
          res.data.data.map((ele) => ({
            label: ele.group_name,
            value: ele.group_id,
            location: ele.location,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(dpdesc);

  useEffect(() => {
    handleFetchGroup();
  }, []);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      {/* <Drawer/> */}
      <div className="container1">
        <button className="back-button1" onClick={() => navigate(`/dashboard`)}>
          ← Back
        </button>
        {isModalOpen && (
          <Group
            handleCreateGroup={handleCreateGroup}
            closeModal={closeModal}
            handleFetchGroup={ handleFetchGroup()}
          />
        )}
        <br />
        <br />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form className="recruiter-form">
              {/* Add Recruiter Section */}
              <div className="section">
                <h3>
                  <b>Add Recruiter</b>
                </h3>
                <p className="head">
                  Please fill the user details to add as recruiter
                </p>
                <div className="form-row">
                  <div className="form-group">
                    <label>Employee Name*</label>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Employee Name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-group">
                    <label>Email Address*</label>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Email Address"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label>Phone Number*</label>
                    <Field
                      name="phoneNumber"
                      type="text"
                      placeholder="Phone Number"
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-group"></div>
                </div>
              </div>

              {/* Access Credentials Section */}
              <div className="section">
                <h3 className="head">
                  <b>Access Credentials</b>
                </h3>
                <div className="form-row">
                  <div className="form-group">
                    <label>Username*</label>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Email@gmail.com"
                      disabled
                    />
                    {/* <ErrorMessage name="email" component="div" className="error" /> */}
                  </div>
                  <div className="form-group password-options">
                    <label>Password Option*</label>
                    <div className="radio-group">
                      <label>
                        <input
                          type="radio"
                          value="auto"
                          checked={passwordOption === "auto"}
                          onChange={() => {
                            setPasswordOption("auto");
                            // const autoGeneratedPassword = generatePassword();
                            setFieldValue("password", generatePassword());
                            setFieldValue(
                              "confirmPassword",
                              generatePassword()
                            );
                            setFieldValue("passwordOption", "auto");
                          }}
                        />
                        Auto Generate*
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="custom"
                          checked={passwordOption === "custom"}
                          onChange={() => {
                            setPasswordOption("custom");
                            setFieldValue("passwordOption", "custom");
                          }}
                        />
                        Custom*
                      </label>
                    </div>
                  </div>
                </div>
            
                  <div className="form-row" >
                    <div className="form-group">
                      <label>{passwordOption === "custom"?`Create Password*`:`Auto Generated Password`}</label>
                      <Field
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="************"
                        disabled={passwordOption === "custom"?false:true}
                      />
                         <button
                type="button"
                style={{marginTop:`-45px`, marginLeft:`300px`}}
                // className="show-password"
                onClick={() => toggleShowPassword()}
              >
                {showPassword ? "👁️" : "🙈"}
              </button>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                      />
                    </div>
                    {passwordOption === "custom" && (
                    <div className="form-group">
                      <label>Confirm Password*</label>
                      <Field
                        name="confirmPassword"
                        type={showPassword1 ? "text" : "password"}
                        placeholder="************"
                      />
                            <button
                type="button"
                style={{marginTop:`-45px`, marginLeft:`300px`}}
                // className="show-password"
                onClick={() => toggleShowPassword1()}
              >
                {showPassword1 ? "👁️" : "🙈"}
              </button>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="error"
                      />
                    </div>
                         )}
                  </div>
           
              </div>

              {/* Access Control Section */}
              <div className="section">
                <h3>
                  <b>Access Control</b>
                </h3>
                <p className="head">
                  Please select the group from below dropdown to give access to
                  recruiter or else customize by creating a new group
                </p>
                <div className="form-row">
                  <div className="form-group">
                    <label>Select Group*</label>
                    <Select
                      options={groupname}
                      name="group"
                      value={groupname.find(
                        (option) => option.value === values.group
                      )}
                      onChange={(option) => {
                        setFieldValue("group", option.value);
                        
                      }}
                    />
                    <ErrorMessage
                      name="group"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-group">
                    <label>Select User Type*</label>
                    <Select
                      options={userG}
                      name="user_type"
                      value={userG.find(
                        (option) => option.value === values.user_type
                      )}
                      onChange={(option) => {
                        setFieldValue("user_type", option.value);
                        
                      }}
                    />
                    <ErrorMessage
                      name="user_type"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-group">
                    <div className="group-description">
                      <strong>Selected Group Description</strong>
                      <p>
                            Directories:{groupname.find(
                            (option) => option.value === values.group
                          )?.location?.join(',')}
                           
                          </p>
                      {/* <p>Country: UAE, Saudi Arabia</p>
                    <p>Category: Human Resource, Retail, IT</p>
                    <p>Role: Leasing executive, Market Research Executive</p> */}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="create-group-button"
                    onClick={openModal}
                  >
                    Create New Group
                  </button>
                </div>
              </div>

              {/* Form Actions */}
              <div className="actions">
                <button type="button" className="cancel-button">
                  Cancel
                </button>
                <button type="submit" className="save-button">
                  Save & Proceed
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Box>
  );
};

export default AddRecruiterForm;
