/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import "@react-pdf-viewer/core/lib/styles/index.css";
// import { Viewer } from '@react-pdf-viewer/core';
import PDFViewer from "pdf-viewer-reactjs";
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import "./Card.css";
import Box from "@mui/material/Box";
import folder from "../../assets/images/users/folder.png";
import file from "../../assets/images/users/file.png";
import UploadFileToS3 from "./S3upload";
import Search from "../../layout/Dashboard/Header/HeaderContent/Search";
import axios from "axios";
import Drawer from "../../layout/Dashboard/Drawer";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import mammoth from "mammoth";
import Stepper from "./Stepper";
import { FaHome } from "react-icons/fa";
import FileView from "./FileView";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FileUploadModal from './FileUploadModal';
import MoveFolder from "./MoveFolder";
import LoadingSpinner from "./Loading";
const MySwal = withReactContent(Swal);

// Sample data to represent files and folders
// const items = [
//   { id: 1, type: 'folder', name: 'All Data/Admin', filesCount: 23 },
//   { id: 2, type: 'file', name: 'All Data/Document.docx', content: 'This is a document file content' },
//   { id: 3, type: 'folder', name: 'All Data/Projects', },
//   { id: 4, type: 'file', name: 'All Data/Sample.pdf', }, // Use your PDF link here
//   // Add more items as needed
// ];

// Component to display individual items
const options = [
'Move','Delete'
];

const ITEM_HEIGHT = 48;
const Item = ({ item, onViewFile, setSearch, setFolder,search,handleAllfolders,handleSearch }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);


  const openModal1 = () => {
    setGetItem(item)
    handleClose()
    setModalIsOpen(true);
  };

  const closeModal1 = () => {
    setModalIsOpen(false);
  };

  const [getItem, setGetItem]=useState({})
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openModal = (item) => {
    setGetItem(item)
    handleClose()
    setIsModalOpen(true);
  }
  const closeModal = () => setIsModalOpen(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
 
  const onDelete=(item)=>{
    axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/user/delete/folder`, {
      
       path: item.path  // The data to send in the request body
      
 
    })
    .then((res) => {
      // console.log(res.data.hits.hits)
      Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
      search !== "" ? handleSearch() : handleAllfolders();
      console.log(res);
     
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const handleDelete = (item) => {
    handleClose()
    MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Call the delete function passed as a prop
        onDelete(item);
       
      }
    });
  };




  return (
    <>
   
    <div
      className="item-card"
      style={{display:`flex`, justifyContent:`space-between`, padding:`10px 10px 10px 30px`}}
    
    >
      {item.type === "folder" ? (
        <div className="icon-folder"   onClick={() =>
          item.type === "file" ? onViewFile(item) : setFolder(item.path)
        }>
          <img src={folder} alt="Folder Icon" />{" "}
          {/* Replace with your folder icon */}
          <p>{item.name}</p>
          {/* <span>{item.filesCount} files</span> */}
        </div>
      ) : (
        <div className="icon-file"   onClick={() =>
          item.type === "file" ? onViewFile(item) : setFolder(item.path)
        }>
          <img src={file} alt="File Icon" /> {/* Replace with your file icon */}
          <p>{item.name}</p>
        </div>
      )}
       <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          },
        }}
      >
        {/* <MenuItem key='move'  onClick={handleClose}>
          Add Folder
          </MenuItem> */}{
            item.type==`folder`&&
            <MenuItem key='add' onClick={()=>openModal(item)}>
            Add File
               </MenuItem>
          }
     {
      item.type===`file`&&
      <MenuItem key='move'onClick={()=>openModal1(item)}>
          Move File
          </MenuItem>
     }
          
          <MenuItem key='delete'  onClick={()=>handleDelete(item)}>
          Delete
          </MenuItem>
    
      </Menu>
    </div>
      
    </div>
    <FileUploadModal isOpen={isModalOpen} onClose={closeModal} getItem={getItem} search={search} handleAllfolders={handleAllfolders} handleSearch={handleSearch}/>
    <MoveFolder  isOpen={modalIsOpen} onClose={closeModal1} getItem={getItem}  search={search} handleAllfolders={handleAllfolders} handleSearch={handleSearch}/>
    </>
   
  );
};

// Modal Component to view file content with a two-column layout
const FileModal = ({ show, onClose, file, fObject, docContent }) => {
  if (!show) return null;
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  console.log(fObject.headers["content-type"]);
  const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(file)}&embedded=true`;
  return (
    <div className="modal-overlay">
      <div className="modal-content-two-column">
        {/* Left Section - Profile or Static Content */}

        {/* Right Section - Dynamic File Content */}

        <button onClick={onClose} className="close-btn">
          Close
        </button>
        {/* <PDFViewer
      document={{
        url: 'https://pdfobject.com/pdf/sample.pdf'
      }}
    /> */}
        <div className="custom-pdf-viewer">
          {
            fObject.headers["content-type"] === `application/pdf` ? (
              <PDFViewer
                document={{
                  url: file,
                }}
                css="custom-wrapper"
                canvasCss="custom-canvas"
                hideNavbar={true} // Hides the bottom navigation bar
                navigation={{
                  css: {
                    navbarWrapper: "custom-navbar",
                    button: "custom-nav-button",
                  },
                }}
              />
            ) : fObject.headers["content-type"] ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
              //  <DocViewer documents={[{ uri: file }]}  />
              <div
                style={{ width: `50vw`, height: `80vh`, overflow: `auto` }}
                dangerouslySetInnerHTML={{ __html: docContent }}
              />
            ) : (
              <iframe
                src={googleDocsViewerUrl}
                style={{ width: "100%", height: "600px", border: "none" }}
                title="Document Viewer"
              ></iframe>
            )
            //   <iframe
            //   src={`https://view.officeapps.live.com/op/view.aspx?src=${file}`}
            //   style={{ width: '100%', height: '100vh' }}
            //   frameBorder="0"
            // />
          }

          {/* <Worker workerUrl={`"https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js"`}>
                <Viewer fileUrl={file}  />
            </Worker> */}
        </div>
      </div>
    </div>
  );
};

// Main component to render the dashboard
const Dashboard = () => {
  const regId = sessionStorage.getItem("reg_id");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState("");
  const [folder, setFolder] = useState(sessionStorage.getItem(`die_access`));
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [fObject, setFobject] = useState(null);
  const [docContent, setDocContent] = useState("");
  const [isLoading, setIsLoading]=useState(true)
  
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(file)}&embedded=true`;

  const handleViewFile = async (file) => {
    setSelectedFile(file);
    console.log(file);
    // axios.post(`/api/user/pdf/files`,{
    //   file:'All Data/Admin/Abhinav Maurya.pdf'
    // }).then((res)=>{
    //   console.log(res)
    //   const blob = await res.blob(); // Convert the response to a Blob
    // const fileUrl = URL.createObjectURL(blob); // Create a viewable URL from the Blob
    // // return fileUrl
    // console.log(fileUrl)
    // })
    // setIsModalOpen(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/pdf/files`,
        {
          file: file.path,
        },

        {
          // headers: {
          //   'Content-Type': 'application/json', // Ensure this is the correct Content-Type
          //   // 'Authorization': 'Bearer YOUR_TOKEN' // Include any required authorization headers
          // },
          // responseType: "blob", // Important: to handle the file as a Blob/
          responseType: !file.path.includes(`.pdf`)?'arraybuffer': "blob" ,
        }
      );

      const blob = response.data; // Get the Blob from the response
      console.log(response);
      setFobject(response);
      sessionStorage.setItem("fObject", JSON.stringify(response));
      if (response.headers["content-type"] === `application/pdf`) {
        const url = URL.createObjectURL(blob);
        setFile(url);
        sessionStorage.setItem("blobUrl", url);
        console.log(`pdf`, url);
      } else if (
        response.headers["content-type"] ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        // const base64String = arrayBufferToBase64(response.data);

        // // Create a data URL from the base64 string
        // const url = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64String}`;

        // setFile(url);
        // console.log(`doc`,url);
        // Convert ArrayBuffer to Blob
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        // Read the DOC file as ArrayBuffer
        const reader = new FileReader();
        reader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          // Convert DOCX to HTML using mammoth (if DOCX, otherwise you need a conversion)
          const result = await mammoth.convertToHtml({ arrayBuffer });
          setDocContent(result.value); // Set the converted HTML content
        };
        reader.readAsArrayBuffer(blob);
      } else {
      }
      // Create a viewable URL from the Blob

      // setFileUrl(url);

      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching the file:", error);
    }
  };
  console.log("fObj", fObject);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
    sessionStorage.setItem("fObject", null);
    sessionStorage.setItem("blobUrl", "");
  };
  const handleSearch = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/user/search/resume`, {
        search_data: search,
        access_location: sessionStorage.getItem(`die_access`),
      })
      .then((res) => {
        // console.log(res.data.hits.hits)
        console.log(res.data.values);
        setItems(
          res.data.values.map((ele, i) => ({
            id: i + 1,
            type: ele[`type`] === `file` ? "file" : `folder`,
            name: ele[`name`],
            path: ele[`file_path`],
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAllfolders = () => {
    setIsLoading(true)
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/user/fetch/folder`, {
        current_folder:
          folder === "" ? sessionStorage.getItem("die_access") : folder,
      })
      .then((res) => {
        setIsLoading(false)
        setItems(
          res.data.data.map((ele, i) =>
            // { id:ele.id, type:ele[`_index`]===`resume`? 'file':`folder`, name: ele[`_source`].file, url: ele.id }
            ({
              id: i + 1,
              type: ele.folder_type,
              name: ele.file_name,
              path: ele.next_folder,
              folderId: ele.folder_id,
            })
          )
        );
      });
  };

  useEffect(() => {
    search !== "" ? handleSearch() : handleAllfolders();
  }, [search, folder]);

  console.log(search);

  // useEffect(() => {
  //   // Check if the page has been reloaded already
  //   const hasReloaded = sessionStorage.getItem('hasReloaded');

  //   if (!hasReloaded) {
  //     sessionStorage.setItem('hasReloaded', 'true');
  //     window.location.reload();
  //   }
  // }, []);

  useEffect(() => {
    if (fObject !== null) {
      const blob = fObject.data;
      if (fObject?.headers["content-type"] === `application/pdf`) {
        const url = URL.createObjectURL(blob);
        setFile(url);
        // sessionStorage.setItem("blobUrl", url);
        // console.log(`pdf`, url);
      } else if (
        fObject?.headers["content-type"] ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const blob = new Blob([fObject?.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        // Read the DOC file as ArrayBuffer
        const reader = new FileReader();
        reader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          // Convert DOCX to HTML using mammoth (if DOCX, otherwise you need a conversion)
          const result = await mammoth.convertToHtml({ arrayBuffer });
          setDocContent(result.value); // Set the converted HTML content
        };
        reader.readAsArrayBuffer(blob);
      }
    }
  }, [fObject]);

  return (
    <>
    {
      isLoading ?  <LoadingSpinner/>:
  
    <Box sx={{ display: "flex", width: "100%" }}>
      {/* <Drawer/> */}
      <div style={{ width: `100%` }}>
        <div
          className="search-div "
          style={{ display: `flex`, justifyContent: `start` }}
        >
          <Search
            setSearch={setSearch}
            search={search}
            setFolder={setFolder}
            folder={folder}
            fObject={fObject}
          />

          <UploadFileToS3 />
        </div>
        <br />
        {!selectedFile && (
          <div className="dashboard">
            {items.map((item) => (
              <Item
                key={item.id}
                item={item}
                onViewFile={handleViewFile}
                setSearch={setSearch}
                setFolder={setFolder}
                search={search}
                handleAllfolders={handleAllfolders}
                handleSearch={handleSearch}

              />
            ))}
          </div>
        )}
      
        {selectedFile && (
          // <div className="dashboard">
          //   <div>
          //     <div>
          //       {/* Left Section - Profile or Static Content */}

          //       {/* Right Section - Dynamic File Content */}

          //       <button onClick={handleCloseModal} className="close-btn">
          //         Close
          //       </button>

          //       <div className="custom-pdf-viewer">
          //         {fObject?.headers["content-type"] === `application/pdf` ? (
          //           <PDFViewer
          //             document={{
          //               url: file,
          //             }}
          //             css="custom-wrapper"
          //             canvasCss="custom-canvas"
          //             hideNavbar={true} // Hides the bottom navigation bar
          //             navigation={{
          //               css: {
          //                 navbarWrapper: "custom-navbar",
          //                 button: "custom-nav-button",
          //               },
          //             }}
          //           />
          //         ) : fObject?.headers["content-type"] ===
          //           "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
          //           //  <DocViewer documents={[{ uri: file }]}  />
          //           <div
          //             style={{
          //               width: `50vw`,
          //               height: `80vh`,
          //               overflow: `auto`,
          //             }}
          //             dangerouslySetInnerHTML={{ __html: docContent }}
          //           />
          //         ) : (
          //           <iframe
          //             src={googleDocsViewerUrl}
          //             style={{
          //               width: "100%",
          //               height: "600px",
          //               border: "none",
          //             }}
          //             title="Document Viewer"
          //           ></iframe>
          //         )}
          //       </div>
          //     </div>
          //   </div>
          // </div>
         
          <FileView
            show={isModalOpen}
            onClose={handleCloseModal}
            file={file}
            fObject={fObject}
            docContent={docContent}
            selectedFile={selectedFile}
          />
     
        )}
        {/* Modal to view file content */}
        {/* {selectedFile && (
            <FileModal
              show={isModalOpen}
              onClose={handleCloseModal}
              file={file}
              fObject={fObject}
              docContent={docContent} 
            />
          )} */}
             
      </div>
    </Box>
  }
  </>
  );
};

export default Dashboard;
