import { lazy } from "react";

// project import
import Loadable from "../components/Loadable";
import Dashboard from "../layout/Dashboard";
import CardGrid from "../pages/Card/Card";
import EmployeeTable from "../pages/Recruiter/Recruiter";
import AddRecruiterForm from "../pages/authentication/register";
import ProtectedRoute from "./ProtectedRoutes";
// import App from 'pages/authentication/login';
// import App from 'pages/authentication/login';

const Color = Loadable(lazy(() => import("../pages/component-overview/color")));
const Typography = Loadable(
  lazy(() => import("../pages/component-overview/typography"))
);
const Shadow = Loadable(
  lazy(() => import("../pages/component-overview/shadows"))
);
// const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/index')));

// render - sample page
const SamplePage = Loadable(
  lazy(() => import("../pages/extra-pages/sample-page"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",

  element: (
    <ProtectedRoute>
      {" "}
      <Dashboard />
    </ProtectedRoute>
  ),
  // element:<App/>,
  children: [
    {
      path: "/dashboard",
      element: <CardGrid />,
    },
    {
      path: "/recruiters",
      element: <EmployeeTable />,
    },
    {
      path: "/recruiter-register",
      element: <AddRecruiterForm />,
    },
    {
      path: "color",
      element: <Color />,
    },
    // {
    //   path: 'dashboard',
    //   children: [
    //     {
    //       path: 'default',
    //       element: <DashboardDefault />
    //     }
    //   ]
    // },
    {
      path: "sample-page",
      element: <SamplePage />,
    },
    {
      path: "shadow",
      element: <Shadow />,
    },
    {
      path: "typography",
      element: <Typography />,
    },
  ],
};

export default MainRoutes;
