import React, { useState } from 'react';
import './FileUploadModal.css'; // Import the CSS file for styling
import axios from 'axios';
import Swal from 'sweetalert2';

const FileUploadModal = ({ isOpen, onClose , getItem,search,handleAllfolders,handleSearch}) => {
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);

  const [fileType, setFileType]=useState()

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (item) => {
    if (!fileName || !file) {
      alert('Please provide both file name and file.');
      return;
    }else{
        let formData= new FormData
        formData.append(`path`, getItem.path)
        formData.append(`file`, file)
        formData.append(`name`, fileName)
        formData.append(`type`, `file`)
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/new/folder`, formData)
    .then((res) => {
      // console.log(res.data.hits.hits)
      Swal.fire('Added!', 'Your file has been added.', 'success');
      search !== "" ? handleSearch() : handleAllfolders();
      console.log(res);
     
    })
    .catch((err) => {
      console.log(err);
    });
    }

    // Handle file upload logic here
    console.log('File Name:', fileName);
    console.log('File:', file);

    // Close the modal after submission
    onClose();
  };

  if (!isOpen) return null; // Return nothing if modal is not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Upload File</h2>
        <div className="form-group">
          <label>File Name:</label>
          <input
            type="text"
            value={fileName}
            onChange={handleFileNameChange}
            placeholder="Enter file name"
          />
        </div>
       
        <div className="form-group">
          <label>File:</label>
          <input type="file" onChange={handleFileChange} />
        </div>
        <div className="modal-actions">
          <button onClick={handleSubmit}>Upload</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default FileUploadModal;
