/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */

// material-ui
import useMediaQuery from "@mui/material/useMediaQuery";
// import IconButton from '@mui/material/IconButton';
// import Link from '@mui/material/Link';
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
// project import
import Search from "./Search";
import Profile from "./Profile";
// import Notification from './Notification';
import MobileSection from "./MobileSection";
import useImg from "../../../../assets/images/users/Frame.svg"; // project import
import userList from "../../../../assets/images/users/userList.png";
import ButtonBase from "@mui/material/ButtonBase";
import CardContent from "@mui/material/CardContent";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { MenuItem, Badge } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import NotificationItem from "examples/Items/NotificationItem";
// import team2 from "assets/images/team-2.jpg";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import Notifications from "react-notifications-menu";
import moment from "moment";
// import Box from "@mui/material/Box";
// import { GithubOutlined } from '@ant-design/icons';
// project import
import Avatar from "../../../../components/@extended/Avatar";
import MainCard from "../../../../components/MainCard";
import Transitions from "../../../../components/@extended/Transitions";
// import { useNavigate } from "react-router-dom";
// assets
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import avatar1 from "../../../../assets/images/users/avatar-1.png";

// ==============================|| HEADER - CONTENT ||============================== //

const admLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      opacity="0.983"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5078 1.14854C14.2094 1.05284 15.35 2.33409 14.9297 4.99229C14.9945 5.46325 14.9242 5.91639 14.7187 6.35167C14.5116 6.65973 14.3397 6.98785 14.2031 7.33604C14.0741 7.54735 13.9334 7.75046 13.7812 7.94542C13.5647 8.98262 12.9631 9.70135 11.9765 10.1017C10.99 9.70135 10.3884 8.98262 10.1718 7.94542C9.81142 7.44353 9.49896 6.91225 9.23435 6.35167C9.02885 5.91639 8.95853 5.46325 9.02341 4.99229C8.66163 3.76124 8.92722 2.69092 9.82028 1.78136C10.3404 1.44411 10.9029 1.23317 11.5078 1.14854Z"
      fill="black"
    />
    <path
      opacity="0.976"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.82031 8.74219C9.98681 9.70908 10.1743 10.6778 10.3828 11.6484C10.5833 11.3617 10.7942 11.0804 11.0156 10.8047C11.063 10.7655 11.1177 10.7498 11.1797 10.7578C11.2314 11.0301 11.3251 11.2879 11.4609 11.5312C11.3139 12.0772 11.2202 12.6319 11.1797 13.1953C9.19148 13.1967 7.20712 13.1967 5.22656 13.1953C5.42475 12.2993 5.77631 11.4712 6.28125 10.7109C6.54806 10.4095 6.86841 10.1829 7.24219 10.0312C7.95933 9.82341 8.64684 9.54216 9.30469 9.1875C9.51323 9.08044 9.68512 8.93198 9.82031 8.74219Z"
      fill="black"
    />
    <path
      opacity="0.977"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0859 8.74219C14.4669 9.09722 14.9044 9.37064 15.3984 9.5625C15.8288 9.7372 16.2663 9.89348 16.7109 10.0312C17.3935 10.3232 17.8701 10.8154 18.1406 11.5078C18.3966 12.049 18.5918 12.6115 18.7266 13.1953C16.746 13.1967 14.7616 13.1967 12.7734 13.1953C12.7125 12.5954 12.6265 12.0016 12.5156 11.4141C12.6431 11.1877 12.7525 10.9533 12.8438 10.7109C13.0978 11.0168 13.34 11.3293 13.5703 11.6484C13.7927 10.6889 13.9646 9.72009 14.0859 8.74219Z"
      fill="black"
    />
    <path
      opacity="0.99"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.41406 13.4766C9.78909 13.4687 14.1641 13.4766 18.5391 13.5C19.1801 13.6177 19.5395 14.0005 19.6172 14.6484C19.25 16.8203 18.8828 18.9922 18.5156 21.1641C18.4035 21.6355 18.1145 21.9402 17.6484 22.0781C13.8672 22.1094 10.086 22.1094 6.30469 22.0781C5.83861 21.9402 5.54958 21.6355 5.4375 21.1641C5.07033 18.9922 4.70311 16.8203 4.33594 14.6484C4.42225 13.9998 4.78163 13.6092 5.41406 13.4766ZM11.7891 17.2266C12.2807 17.1791 12.4916 17.3979 12.4219 17.8828C12.285 18.1418 12.0741 18.2277 11.7891 18.1406C11.5301 18.0038 11.4442 17.7929 11.5312 17.5078C11.595 17.3896 11.6809 17.2958 11.7891 17.2266Z"
      fill="black"
    />
    <path
      opacity="0.934"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.97651 22.2891C9.97654 22.2812 13.9765 22.2891 17.9765 22.3125C18.1098 22.4821 18.0942 22.6384 17.9296 22.7813C13.9609 22.8125 9.99215 22.8125 6.02338 22.7813C5.85628 22.6305 5.84067 22.4664 5.97651 22.2891Z"
      fill="black"
    />
  </svg>
);

export default function HeaderContent() {
  const downLG = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const Navigate = useNavigate();
  const regId = sessionStorage.getItem("reg_id");
  // notifications
  const [openMenu, setOpenMenu] = useState(false);
  const [dataLength, setDataLength] = useState(true);
  const [modalHeight, setModalHeight] = useState(false);
  const [data, setData] = useState([]);
  const [datakeys, setDatakeys] = useState([]);
  const [message, setMessage] = useState("");
  const [anchorE1, setAnchorE1] = useState(null);
  const open1 = Boolean(anchorE1);
  console.log(window.location.pathname);
  const path = window.location.pathname;
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    Navigate("/login");
  };
  const handleClose1 = () => {
    setAnchorE1(null);
  };
  const handleClick1 = (event) => {
    setAnchorE1(event.currentTarget);
  };
  // const renderMenu = () => (
  //   <Menu
  //     anchorEl={openMenu}
  //     anchorReference={null}
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "left",
  //     }}
  //     open={Boolean(openMenu)}
  //     onClose={handleCloseMenu}
  //     sx={{ mt: 2 }}
  //   >
  //     <NotificationItem
  //       image={<img src={team2} alt="person" />}
  //       title={["New message", "from Laur"]}
  //       date="13 minutes ago"
  //       onClick={handleCloseMenu}
  //     />
  //     <NotificationItem
  //       image={<img src={logoSpotify} alt="person" />}
  //       title={["New album", "by Travis Scott"]}
  //       date="1 day"
  //       onClick={handleCloseMenu}
  //     />
  //     <NotificationItem
  //       color="secondary"
  //       image={
  //         <Icon
  //           fontSize="small"
  //           sx={{ color: ({ palette: { white } }) => white.main }}
  //         >
  //           payment
  //         </Icon>
  //       }
  //       title={["", "Payment successfully completed"]}
  //       date="2 days"
  //       onClick={handleCloseMenu}
  //     />
  //   </Menu>
  // );
  const handleCloseMenu = () => setOpenMenu(false);
  const FetchNotifications = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/notification`
      );
      const data = await res.data;
      setData(
        data
          ?.map((v) => {
            return {
              id: v.id,
              image: admLogo,
              // v.receiver_type === "JP"
              //   ? jpLogo
              //   : v.receiver_type === "SA"
              //     ? admLogo
              //     : v.receiver_type === "CRM"
              //       ? crmLogo
              //       : v.receiver_type === "CP"
              //         ? cpLogo
              //         : v.receiver_type === "JobFair"
              //           ? jfLogo
              //           : v.receiver_type === "ADM"
              //             ? admLogo
              //             : "",
              message: v.notification,
              key: v.receiver_type,
              read: v.read,
              notification_type: v.notification_type,
              timestamp: v.timestamp
                ? moment.utc(v.timestamp).format("MMMM Do YYYY, h:mm:ss a")
                : "",
              notification_to: v.notification_to,
            };
          })
          .sort((a, b) => a?.timestamp - b?.timestamp)
        // .reverse()
      );

      setDatakeys(data?.map((v) => v.id));
    } catch (err) {
      setData([]);
    }
  };

  const ReadNotification = async (id) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/notification`,
        {
          ids: [id],
        }
      );
      const data = await res.data;
      FetchNotifications();
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteAllNotification = async (id) => {
    const data = {
      ids: [id],
    };
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/user/notification`,
        { data }
      );
      const datares = await res.data;
      FetchNotifications();
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteAllNotifications = async (id) => {
    const data = {
      ids: datakeys,
    };
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/user/notification`,
        { data }
      );
      const datares = await res.data;
      FetchNotifications();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    FetchNotifications();
  }, []);
  return (
    <>
      {/* {!downLG  && <Search />} */}
      {downLG && <Box sx={{ width: "100%", ml: 1 }} />}
      {/* <IconButton
        component={Link}
        href="https://github.com/codedthemes/mantis-free-react-admin-template"
        target="_blank"
        disableRipple
        color="secondary"
        title="Download Free Version"
        sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
      >
        <GithubOutlined />
      </IconButton> */}
      {/* <Notification /> */}
      <div
        style={{
          width: `80vw`,
          display: `flex`,
          alignItems: `flex-end`,
          justifyContent: `flex-end`,
        }}
      >
        {/* <button style={{width:'220px', backgroundColor:`#FB8022`,border:`none`, padding:`10px 10px`, borderRadius:`6px`, height:`40px`, color:`white`, display:`flex`, alignItems:`center`, justifyContent:`center`}} onClick={()=>Navigate(`/recruiter-register`)}><img src={useImg}/> &nbsp; </button> */}
        {sessionStorage.getItem(`user_type`) === `admin` && (
          <button
            className="hover-button1"
            onClick={() => Navigate(`/recruiter-register`)}
          >
            <span className="icon1">
              <img src={useImg} />
            </span>

            <span className="text1">Add Recruiter </span>
          </button>
        )}
      </div>
      &nbsp;&nbsp;&nbsp;
      {sessionStorage.getItem(`user_type`) === `admin` && (
        <Tooltip title="Recruiter List">
          <img
            src={userList}
            onClick={() => Navigate(`/recruiters`)}
            style={{ marginTop: `8px`, cursor: `pointer` }}
          />{" "}
        </Tooltip>
      )}{" "}
      &nbsp;&nbsp;&nbsp;
      {/* {!downLG && <Profile />} */}
      {/* <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <div container justifyContent="space-between" alignItems="center">*/}
      {sessionStorage.getItem(`user_type`) === `admin` && (
        <Box sx={{ marginRight: "15px" }}>
          <Badge
            badgeContent={
              // dataLength
              //   ? data?.filter((v) => v.read === 0)?.length === 0
              //     ? ""
              //     : data?.filter((v) => v.read === 0)?.length
              //   : ""
              data?.filter((v) => v.read === 0)?.length !== 0
                ? data?.filter((v) => v.read === 0)?.length
                : ""
            }
            sx={{ color: "#000", cursor: "pointer", mt: 0 }}
            onClick={(event) => {
              handleClick1(event);
              setDataLength(false);
            }}
            size="small"
            aria-controls={open1 ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
          >
            <NotificationsIcon color="black" />
          </Badge>

          <Menu
            anchorEl={anchorE1}
            id="account-menu"
            open={open1}
            onClose={handleClose1}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                  m: 0,
                  p: 0,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Box sx={{ width: "350px", height: "100%", p: 0, m: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 1,
                  p: 1,
                }}
              >
                <Typography fontSize="15px" fontWeight="medium">
                  Notifications
                </Typography>

                {data?.length !== 0 ? (
                  <Typography
                    fontSize="15px"
                    fontWeight="medium"
                    color="#3B5092"
                    sx={{ "&:hover": { cursor: "pointer" } }}
                    onClick={() => DeleteAllNotifications()}
                  >
                    <u
                      style={{
                        color: "#3B5092",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      Clear all
                    </u>
                  </Typography>
                ) : null}
              </Box>

              <Box
                sx={{
                  height: modalHeight ? "530px" : "320px",
                  overflow: "auto",
                }}
              >
                {data.map((text) => {
                  return (
                    <Box
                      key={text.id}
                      sx={{
                        background:
                          text.read === 0
                            ? "rgb(244, 248, 250)"
                            : text.read === 1
                              ? "#FFFFFF"
                              : "rgb(244, 248, 250)",
                        width: "100%",
                        p: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        borderBottom: "0.5px solid grey",
                      }}
                      onClick={() => ReadNotification(text.id ? text.id : "")}
                    >
                      <Box
                        sx={{
                          height: "35px",
                          width: "35px",
                          background: "rgb(244, 248, 250)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          border: "1px solid grey",
                        }}
                      >
                        {text.image}
                      </Box>

                      <Box sx={{ m: 1, width: "260px" }}>
                        <Typography
                          fontSize="13px"
                          fontWeight="normal"
                          mb={0.75}
                        >
                          {text.message}
                        </Typography>

                        <Typography
                          fontSize="12px"
                          fontWeight="normal"
                          color="grey"
                        >
                          {text.timestamp}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          height: "35px",
                          width: "35px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            DeleteAllNotification(text.id ? text.id : "")
                          }
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                {modalHeight ? (
                  <Typography
                    fontSize="14px"
                    fontWeight="medium"
                    cursor="pointer"
                    // color="#3B5092"
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => setModalHeight(false)}
                  >
                    <span style={{ color: "#3B5092" }}>See less</span>{" "}
                    <KeyboardArrowUpIcon fontSize="small" />
                  </Typography>
                ) : (
                  <Typography
                    fontSize="14px"
                    fontWeight="medium"
                    color="#3B5092"
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => setModalHeight(true)}
                  >
                    <span style={{ color: "#3B5092" }}>View more</span>{" "}
                    <KeyboardArrowDownIcon fontSize="small" />
                  </Typography>
                )}
              </Box>
            </Box>
          </Menu>
        </Box>
      )}
      <div item>
        <Stack direction="row" spacing={1.25} alignItems="center">
          <img
            alt="profile user"
            src={avatar1 || ""}
            style={{ width: "32px", height: "32px" }} // Use standard style for testing
          />
          <Stack>
            <Typography variant="h6">
              {sessionStorage.getItem("name")}
            </Typography>
          </Stack>
        </Stack>
      </div>{" "}
      &nbsp;&nbsp;
      <div item>
        <Tooltip title="Logout">
          <IconButton
            size="large"
            sx={{ color: "text.primary" }}
            onClick={() => logout()}
          >
            <LogoutOutlined />
          </IconButton>
        </Tooltip>
      </div>
      {/* </div>
                  </CardContent> */}
      {downLG && <MobileSection />}
    </>
  );
}
