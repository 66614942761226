/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import "./Group.css";
import Select from "react-select";
import GroupCreatedModal from "../../components/Success/GroupModal";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
// import Select from "react-select";

const Group = ({ handleCreateGroup, closeModal,   handleFetchGroup }) => {
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [group, setGroup] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [directory, setDirectory] = useState([]);
  const countryOptions = [
    { value: "dubai", label: "Dubai" },
    { value: "rabat", label: "Rabat" },
  ];

  console.log(selectedCountries);
  console.log(group);
  console.log(isModalOpen);

  const validationSchema = Yup.object({
    groupName: Yup.string().required("Group name is required"),
    selectedDirectories: Yup.array()
      .min(1, "At least one directory must be selected")
      .required("Please select at least one directory"),
  });

  const formik = useFormik({
    initialValues: {
      groupName: "",
      selectedDirectories: [],
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submissionr
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/user/access/group`, {
          group_name: values.groupName,
          location: values.selectedDirectories
            .map((ele) => ele.value)
            .join(","),
        })
        .then((res) => {
          // console.log(res.data)
          closeModal();
          Swal.fire({
            title: res.data.message,
            icon: "success",
            text: `Directories Access:${res.data.access_location.join(",")}`,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: `
                <i class="fa fa-thumbs-up"></i> Great!
              `,
          });
       
          handleFetchGroup()
          // setDirectory(res.data.data.map((ele)=>({label:ele.key, value:ele.value})))
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // handleFetchGroup()
          setModalOpen(true);
        });
      // console.log(values);
    },
  });

  const handleFetchLocation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/files/access`)
      .then((res) => {
        console.log(res.data);
        setDirectory(
          res.data.data.map((ele) => ({ label: ele.key, value: ele.value }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleFetchLocation();
  }, []);

  const categoryOptions = [
    { value: "human_resource", label: "Human Resource" },
    { value: "retail", label: "Retail" },
    { value: "it", label: "Information Technology" },
  ];

  const roleOptions = [
    { value: "market_research_executive", label: "Market Research Executive" },
  ];
  return (
    // <div className="App">

    <>
      <GroupCreatedModal isOpen={isModalOpen} />
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Specify Group Details</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label htmlFor="groupName">Name Of Group*</label>
              <input
                id="groupName"
                name="groupName"
                type="text"
                placeholder="Enter Group Name"
                onChange={formik.handleChange}
                value={formik.values.groupName}
                onBlur={formik.handleBlur}
              />
              {formik.touched.groupName && formik.errors.groupName ? (
                <div className="error">{formik.errors.groupName}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label>Directories*</label>
              <Select
                isMulti
                options={directory}
                value={formik.values.selectedDirectories}
                onChange={(selectedOptions) =>
                  formik.setFieldValue("selectedDirectories", selectedOptions)
                }
                onBlur={() =>
                  formik.setFieldTouched("selectedDirectories", true)
                }
              />
              {formik.touched.selectedDirectories &&
              formik.errors.selectedDirectories ? (
                <div className="error">{formik.errors.selectedDirectories}</div>
              ) : null}
            </div>

            <div className="form-buttons">
              <button type="button" onClick={closeModal}>
                Cancel
              </button>
              <button type="submit">Create Group</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Group;
