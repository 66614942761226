// material-ui
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { FaInfoCircle, FaHome } from "react-icons/fa"; // Import both icons from React Icons
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css"; // Import the CSS for the tooltip
// assets
import SearchOutlined from "@ant-design/icons/SearchOutlined";

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

export default function Search({ setSearch, search, setFolder, folder,  fObject }) {
  // Handle search reset and set folder
  const HandleSearch = () => {
    setSearch(""); // Reset search input
    setFolder(sessionStorage.getItem("die_access")); // Retrieve folder from session storage
  };

  return (
    <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }}>
      <FormControl
        sx={{
          width: { xs: "100%", md: 224 },
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* Conditional rendering for the home icon */}
              <div
              style={{
                border: "1px solid #dfdfdf",
                padding: "2px 10px",
                display: "flex",
                alignItems: "center",
                borderRadius: "7px",
                cursor: "pointer",
              }}
              onClick={HandleSearch}
            >
              <FaHome size={24} />
            </div>
        
        &nbsp;&nbsp;&nbsp;

        {/* Search input with search icon */}
        <OutlinedInput
          size="small"
          id="header-search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setFolder(""); // Reset folder when searching
          }}
          startAdornment={
            <InputAdornment position="start" sx={{ mr: -0.5 }}>
              <SearchOutlined />
            </InputAdornment>
          }
          aria-describedby="header-search-text"
          inputProps={{
            "aria-label": "search",
          }}
          placeholder="Search"
        />
        &nbsp;&nbsp;

        {/* Tooltip with info icon */}
        <div style={{ marginTop: "10px" }}>
          <FaInfoCircle
            id="info-icon" // Unique ID for the tooltip
            size={20}
            color="#007BFF"
            style={{ cursor: "pointer" }}
          />
          <ReactTooltip
            anchorId="info-icon" // ID of the element to attach the tooltip
            place="bottom"
            content="This search is across the entire portal."
            style={{ backgroundColor: "#333", color: "#fff" }} // Custom style
          />
        </div>
      </FormControl>
    </Box>
  );
}
