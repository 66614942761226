import React, { useEffect, useState } from "react";
import Modal from "react-modal"; // If using react-modal
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import './FileUploadModal.css'; // Import the CSS file for styling
// Dummy folder options for the select dropdown
const folderOptions = [
  { value: "folder1", label: "Folder 1" },
  { value: "folder2", label: "Folder 2" },
  { value: "folder3", label: "Folder 3" },
];

// Custom styles for the modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    padding: "20px",
  },
};

const MoveFolder = ({isOpen,onClose,getItem,search,handleAllfolders,handleSearch}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [directory, setDirectory] = useState([]);
  const handleFetchLocation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/files/access`)
      .then((res) => {
        console.log(res.data);
        setDirectory(
          res.data.data.map((ele) => ({ label: ele.key, value: ele.value }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(()=>{
    handleFetchLocation()
  },[])
  const handleViewFile = async (item) => {
  
   
    // axios.post(`/api/user/pdf/files`,{
    //   file:'All Data/Admin/Abhinav Maurya.pdf'
    // }).then((res)=>{
    //   console.log(res)
    //   const blob = await res.blob(); // Convert the response to a Blob
    // const fileUrl = URL.createObjectURL(blob); // Create a viewable URL from the Blob
    // // return fileUrl
    // console.log(fileUrl)
    // })
    // setIsModalOpen(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/pdf/files`,
        {
          file: item.path,
        },

        {
          // headers: {
          //   'Content-Type': 'application/json', // Ensure this is the correct Content-Type
          //   // 'Authorization': 'Bearer YOUR_TOKEN' // Include any required authorization headers
          // },
          // responseType: "blob", // Important: to handle the file as a Blob/
          responseType:  "blob" ,
        }
      );

      const blob = response.data; // Get the Blob from the response
      handleSubmit(blob,item)
      
    } catch (error) {
      console.error("Error fetching the file:", error);
    }
  };
  
  const handleSubmit = (blob,item) => {
    if (!selectedFolder) {
      alert('Please provide Destination path.');
      return;
    }else{
        let formData= new FormData
        formData.append(`source`,item.path)
        formData.append(`dest`, selectedFolder.label)
        formData.append(`file`, blob)
        formData.append(`file_name`, item.name)
        formData.append(`type`, `file`)
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/move/folder`, formData)
    .then((res) => {
      // console.log(res.data.hits.hits)
      Swal.fire('Added!', 'Your file has been added.', 'success');
      console.log(res);
     
    })
    .catch((err) => {
      console.log(err);
    });
    }

    // Handle file upload logic here
    // console.log('File Name:', fileName);
    // console.log('File:', file);

    // Close the modal after submission
    // onClose();
  };

  const handleSelectChange = (option) => {
    setSelectedFolder(option);
  };

  const handleConfirm = () => {
    alert(`Selected Folder: ${selectedFolder?.label}`);
    onClose();
  };

  return (
 <>
  

      {/* Modal component */}
      <Modal isOpen={isOpen} onRequestClose={onClose} className="modal-overlay" >
      <div className="modal-content" style={{height:`300px`, }}>
        <h2>Select Destination Folder</h2>

        {/* React-select dropdown */}
        <Select
          options={directory}
          value={selectedFolder}
          onChange={handleSelectChange}
          placeholder="Choose folder"
        />

<div className="modal-actions">
          <button onClick={()=>handleViewFile(getItem)} >Upload</button>
          <button onClick={()=>onClose()} >Cancel</button>
        </div>
        </div>
      </Modal>
      </>
  );
};

export default MoveFolder;
