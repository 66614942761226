// AuthContext.js
import React, { createContext, useContext, useState ,useMemo, useEffect} from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
   console.log(user)
   console.log(isAuthenticated)
  // Initialize state from localStorage
  useEffect(() => {
    const regId =sessionStorage.getItem('reg_id');
    const email =sessionStorage.getItem('email');
    const name =sessionStorage.getItem('name');
    const dieAccess =sessionStorage.getItem('die_access');
    const accessToken =  sessionStorage.getItem('access_token');
    const refreshToken =  sessionStorage.getItem('refresh_token');

   
      setIsAuthenticated(true);
      setUser({
        reg_id: regId,
        email,
        name,
        die_access: dieAccess,
        access_token: accessToken,
        refresh_token: refreshToken,
      });
    
  }, []);

  const login = (userData) => {
    setIsAuthenticated(true);
    setUser(userData);

    // Store in localStorage
   sessionStorage.setItem('reg_id', userData.reg_id);
   sessionStorage.setItem('email', userData.email);
   sessionStorage.setItem('name', userData.name);
   sessionStorage.setItem('die_access', userData.die_access);
   sessionStorage.setItem('access_token', userData.access_token);
   sessionStorage.setItem('refresh_token', userData.refresh_token);
  };

//   const logout = () => {
//     setIsAuthenticated(false);
//     setUser(null);

//     // Clear localStorage
//     localStorage.clear();
//   };

const contextValue = useMemo(() => ({ isAuthenticated, setIsAuthenticated}), [isAuthenticated]);
  return (
    <AuthContext.Provider value={{ contextValue, login }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);